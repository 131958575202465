import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import { orange } from '@material-ui/core/colors';
import clsx from 'clsx';
import EditIcon from '@material-ui/icons/Edit';
import * as All from 'react-syntax-highlighter/dist/esm/styles/hljs';
// import TextField from '@material-ui/core/TextField';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import Avatar from '@material-ui/core/Avatar';


const useStyles = makeStyles((theme) => ({
  // root: {
  //   display: 'flex',
  //   flexWrap: 'wrap',
  //   '& > *': {
  //     padding: theme.spacing(1),
  //     //width: '25ch',
  //   },
  // },
  cardtitle:{
    margin: theme.spacing(1)
  },
  widthFull: {
    width: '100%',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(10),
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: orange[500],
    '&:hover': {
      backgroundColor: orange[600],
    },
  },
}));

function copyTo(codeString){
  //navigator.clipboard.writeText(this.state.textToCopy);
  navigator.clipboard.writeText(codeString);
};

export default function DivCode(props) {
  //LISTA TODOS LOS LENGUAJES DISPONIBLES
  //console.log(SyntaxHighlighter.supportedLanguages);

  const codeString = props.code;
  const classes = useStyles();

  

  return (

    <div>
      <Card className={classes.cardtitle}>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" alt={props.author !== undefined? props.author : 'S'} className={classes.avatar} src={props.infoSnippet.avatar}>
          </Avatar>
          }
          action={
            <IconButton aria-label="copy" onClick={() => {copyTo(codeString) }}>
              <FileCopyOutlinedIcon />
            </IconButton>
          }
          title={props.infoSnippet.title}
          subheader={(props.infoSnippet.author !== undefined? props.infoSnippet.author : '') + ' - ' +  props.infoSnippet.date_at}
        />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {props.infoSnippet.description}
        </Typography>
        </CardContent>
      </Card>

      <SyntaxHighlighter language={props.languaje} style={All.atelierSulphurpoolDark} //style={atomOneDarkReasonable} 
        showLineNumbers='true'
      //wrapLines={true}
      >
        {codeString}
      </SyntaxHighlighter>
      <Fab className={clsx(classes.fab, classes.fabGreen)} aria-label="edit" onClick={() => props.editSnippet(props.infoSnippet,codeString)}>
        <EditIcon />
      </Fab>
    </div>
  );
};