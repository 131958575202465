import logo from './logo.svg';
import './App.css';
import { Switch, Route } from 'react-router-dom';

import Home from './pages/Home';
import Oauth from './pages/Oauth';
import NotFound from './pages/NotFound';
import SnippetsState from './components/context/SnippetsState';
import ReduxToastr from './components/notificaciones';
import {Provider} from 'react-redux';

import './index.css';
import './components/notificaciones/styles/index.css';

function App(props) {
  return (
    
      <Provider store={props.store}>
      <SnippetsState>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/Oauth' component={Oauth} />
          {/* <Route path='/detail/:movieId' component={Detail} /> */}
          <Route component={NotFound} />
        </Switch>
      </SnippetsState>
      <ReduxToastr
      timeOut={4000}
      newestOnTop={false}
      preventDuplicates
      position="top-right"
      getState={(state) => state.toastr} // This is the default
      transitionIn="bounceIn"
      transitionOut="bounceOut"
      progressBar
      closeOnToastrClick/>
      </Provider>
    
  );
}

export default App;
