import {
    GET_SNIPPETS
    , GET_SNIPPET
    , ADD_SNIPPET
    , DELETE_SNIPPET
    , FILTER_SNIPPETS
    , CLEAR_FILTER
} from './types';

export default (state, action) => {
    switch  (action.type)
    {
        case GET_SNIPPETS:
            return {
                ...state,
                snippets: action.payload,
                // loading: false,
                };
        case GET_SNIPPET:
            return {
                ...state,
                snippet: action.payload,
                // loading: false,
                };
        case ADD_SNIPPET:
            return {
                ...state,
                snippets: [action.payload, ...state.snippets],
                // loading: false,
            };
        case DELETE_SNIPPET:
            return {
                ...state,
                snippets: state.snippets.filter(
                    (snippet) => snippet.id !== action.payload
                    ),
                // loading: false,
                };
        case FILTER_SNIPPETS:
            return {
                ...state,
                filtered: state.snippets.filter((snippet) => {
                const regExp = new RegExp(`${action.payload}`, 'gi');
                return (
                    //console.log(snippet)
                    
                    snippet.title.match(regExp) 
                    || snippet.description.match(regExp)
                    || snippet.file_name.match(regExp)
                    // || snippet.author.email.match(regExp)
                    || snippet.author.name.match(regExp)
                    || snippet.author.username.match(regExp)
                );
                }),
            };
            case CLEAR_FILTER:
            return {
                ...state,
                filtered: null,
            };
        default:
            return state;
    }
}