import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

export const ButtonBackToHome = () => (


  <Button variant="contained" color="primary" component={Link} to={'/'}>
    <ArrowBackIosIcon /> &nbsp;Volver al inicio
  </Button>
)
