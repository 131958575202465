import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { deepOrange } from '@material-ui/core/colors';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import Fab from '@material-ui/core/Fab';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';

const useStyles = makeStyles((theme) => ({
  margin: {
    position: 'absolute',
    right: theme.spacing(2),
    marginTop: -70
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  nowraptext:{
    overflow: 'hidden',
    paddingRight: 60
  }
}));



export default function ListItemSnippet(props) {
  const classes = useStyles();
  
  
  return (
    <div>
      <ListItem button className={classes.buttonRecorte} selected={props.selectedIndex} onClick={props.OnClick}>
        <ListItemAvatar >
          <Avatar className={classes.orange} alt={props.author !== undefined? props.author : 'S'} src={props.image} />
        </ListItemAvatar>
          <ListItemText className={classes.nowraptext} primary={props.name} secondary={(props.author !== undefined? props.author + ' - ' : '') + (props.date !== undefined? props.date : '')} />
        
      </ListItem>
      <Fab size="small" color="secondary" aria-label="add" className={useStyles().margin} onClick={props.OnClickDelete}>
        <DeleteRoundedIcon />
      </Fab>
    </div>
  )
}
