import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItemSnippet from './ListItemSnippet';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import DivCode from '../components/DivCode';
import axios from 'axios';
import FormEdit from './FormEdit';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';
import { blue } from '@material-ui/core/colors';

// import EditorCode from './EditorCode';

import SnippetsContext from '../components/context/SnippetsContext';
import { get_extension } from '../components/AuxCustom';
import { get_token, refreshToken,session_active } from '../components/AuthCustom';


import { toastr } from './../components/notificaciones';
import { get_urlSnippets } from './AuxSistema';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        //maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    list: {
        //maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    fabGreen: {
        color: theme.palette.common.white,
        backgroundColor: blue[500],
        '&:hover': {
            backgroundColor: blue[600],
        },
    },
}));




export default function FolderList(props) {
    const classes = useStyles();
    const [languaje, setLanguaje] = useState('html');
    const [code, setCode] = useState('');
    const [snippet_id, setSnippet_id] = useState(0);
    const [view, setView] = useState(props.view);

    const [InfoSnippet, setInfoSnippet] = useState({ filename: 'Name file', title: 'Title', avatar: 'R', date_at: '01/01/2020', author: 'Author', description: 'Description' });

    const snippetContext = useContext(SnippetsContext);
    const { deleteSnippet, addSnippet } = snippetContext;

    const GuardarSnippet = (event) => {
        try {
            // event.preventDefault();
            session_active().then((result) => {
                props.setLogueado(true);
            }).catch((err) => {
                props.setLogueado(false);
                return;
            });    

            var config = {};
            var data = {};
            var edicion = false;
            //console.log(event.target.btnSave.value);
            if (snippet_id > 0) {
                //console.log('updateo');

                data = JSON.stringify({ "title": event.title, "description": event.description, "visibility": event.chk_visibility ? "public" : "private", "files": [{ "content": event.snippet, "action": "update", "previous_path": event.file_name, "file_path": event.file_name }] });



                config = {
                    method: 'put',
                    url: get_urlSnippets() + '/api/v4/snippets/' + snippet_id,
                    headers: {
                        // 'PRIVATE-TOKEN': get_token(), 
                        'Authorization': 'Bearer ' + get_token(),
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                edicion = true;

            }
            else {
                //console.log('nuevo');
                data = JSON.stringify({ "title": event.title, "description": event.description, "visibility": event.chk_visibility ? "public" : "private", "files": [{ "content": event.snippet, "file_path": event.file_name }] });


                config = {
                    method: 'post',
                    url: get_urlSnippets() + '/api/v4/snippets/',
                    headers: {
                        'Authorization': 'Bearer ' + get_token(),
                        //'PRIVATE-TOKEN': get_token(), 
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
            }

            axios(config) //PETICION PARA GAURDAR EL SNIPPET
                .then(function (response) {
                    console.log(`🚀 ~ file: ListFiles.js:114 ~ response:`, response);
                    //console.log(response.data);
                    //console.log(target);
                    if (response.error === "invalid_token") {
                        console.log(`🚀 ~ file: ListFiles.js:116 ~ response.error:`, response.error);
                        //ACTUALIZAR EL TOKEN CON EL REFRESH TOKEN
                        refreshToken().then((result) => {
                            axios(config)
                                .then(function (response) {
                                    console.log(`🚀 ~ file: ListFiles.js:122 (refrescando token guardado)~ response:`, response);

                                    //console.log(response.data);
                                    //console.log(target);
                                    if (!edicion)
                                        addSnippet(response.data);
                                    setCode(event.snippet);
                                    setInfoSnippet({ filename: response.data.file_name, title: response.data.title, avatar: response.data.author.avatar_url, date_at: response.data.update_at, author: response.data.author.name, description: response.data.description, id: response.data.id, visibility: response.data.visibility });
                                    setView('code');
                                    setSnippet_id(0);
                                    toastr.success("Save!!", "Saved successfully!!");
                                })
                                .catch(function (error) {
                                    console.log(error);
                                    toastr.error("Error!!", "An error has ocurred!! (" + error + ")");
                                });
                        }).catch((err) => {
                            console.log(`🚀 ~ file: ListFiles.js:144 ~ refreshToken ~ err:`, err);
                            // AQUI SE TENDRIA QUE INVOCAR UN METODO PARA GUARDAR LA INFO QUE HAY PENDIENTE POR GUARDAR Y NO SE PIERDA POR QUE POR ALGUN MOTIVO YA NO SE PUDO REFRESCAR EL TOKEN Y SE TIENE QUE CERRAR LA SESION
                            saveLocalSnippet(data);
                            session_active().then((result) => {
                                props.setLogueado(true);
                            }).catch((err) => {
                                props.setLogueado(false);
                                return;
                            });
                            return;
                        });;
                    }
                    else {
                        if (!edicion)
                            addSnippet(response.data);
                        setCode(event.snippet);
                        setInfoSnippet({ filename: response.data.file_name, title: response.data.title, avatar: response.data.author.avatar_url, date_at: response.data.update_at, author: response.data.author.name, description: response.data.description, id: response.data.id, visibility: response.data.visibility });
                        setView('code');
                        setSnippet_id(0);
                        toastr.success("Save!!", "Saved successfully!!");
                    }

                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        //ACTUALIZAR EL TOKEN CON EL REFRESH TOKEN
                        refreshToken().then((result) => {//SI APSA EL REFRESH DEL TOKEN VUELVE A INTENTAR GAURDAR
                            axios(config)
                                .then(function (response) {
                                    console.log(`🚀 ~ file: ListFiles.js:160 ~ response:`, response);

                                    //console.log(target);
                                    if (!edicion)
                                        addSnippet(response.data);
                                    setCode(event.snippet);
                                    setInfoSnippet({ filename: response.data.file_name, title: response.data.title, avatar: response.data.author.avatar_url, date_at: response.data.update_at, author: response.data.author.name, description: response.data.description, id: response.data.id, visibility: response.data.visibility });
                                    setView('code');
                                    setSnippet_id(0);
                                    toastr.success("Save!!", "Saved successfully!!");
                                })
                                .catch(function (error) {
                                    console.log(`🚀 ~ file: ListFiles.js:172 ~ refreshToken ~ error.response:`, error.response);
                                    toastr.error("Error!!", "An error has ocurred!! (" + error + ")");
                                });
                        }).catch((err) => {
                            console.log(`🚀 ~ file: ListFiles.js:181 ~ refreshToken ~ err:`, err);
                            // AQUI SE TENDRIA QUE INVOCAR UN METODO PARA GUARDAR LA INFO QUE HAY PENDIENTE POR GUARDAR Y NO SE PIERDA POR QUE POR ALGUN MOTIVO YA NO SE PUDO REFRESCAR EL TOKEN Y SE TIENE QUE CERRAR LA SESION
                            saveLocalSnippet(data);
                            session_active().then((result) => {
                                props.setLogueado(true);
                            }).catch((err) => {
                                props.setLogueado(false);
                                return;
                            });
                            return;
                        });;
                    }
                    console.log(error.response);
                    toastr.error("Error!!", "An error has ocurred!! (" + error + ")");
                });
        } catch (error) {
            console.log(`🚀 ~ file: ListFiles.js ~ line 129 ~ GuardarSnippet ~ error`, error)
        }


    };
    // const snipContext = useContext(SnippetsContext);

    //console.log(snipContext);
    //const { snippet, getSnippet } = snipContext;

    function saveLocalSnippet(data){
        console.log(`🚀 ~ file: ListFiles.js:208 ~ saveLocalSnippet ~ data:`, data);
        
    };

    const getSnippet = (id) => {
        session_active().then((result) => {
            // props.setLogueado(true);
        }).catch((err) => {
            props.setLogueado(false);
            return;
        });
        axios.get("https://gitlab.com/api/v4/snippets/" + id + "/raw", {
            // method: 'get',
            // url: "https://gitlab.com/api/v4/snippets/" + id + "/raw",
            headers: {

                'Authorization': 'Bearer ' + get_token(),
                //'Content-Type': 'application/json'
            }
        }).then(
            (res) => {
                if (res.error === "invalid_token") {
                    //ACTUALIZAR EL TOKEN CON EL REFRESH TOKEN
                    refreshToken().then((result) => {
                        getSnippet(id);
                    }).catch((err) => {
                        // respSnippets = false;
                    });;
                }
                // console.log(res.data);
                setCode(res.data);
                setView('code');
            }
        )
            .catch(function (error) {

                toastr.error("Error!!", "An error has ocurred!! (" + error + ")");
            });
    };

    //METODO CUANDO HACE CLICK EN UN SNIPPET
    const handleListItemClick = (event, id, fileName, title, avatar, date_at, author, description, visibility) => {
        setSelectedIndex(id);
        getSnippet(id);
        setLanguaje(get_extension(fileName));
        setInfoSnippet({ filename: fileName, title: title, avatar: avatar, date_at: date_at, author: author, description: description, id: id, visibility: visibility });
        //componenteeditor("console.log('snippet click');")

        // setCode("console.log('delete click'); \nsegunda linea \ntercera lñinea");
        // setLanguaje('javascript');
        // console.log('snippet click');
    };



    const handleListItemClickDelete = (event, id) => {
        // console.log('delete click');
        // console.log(id);
        deleteSnippet(id);

        toastr.success("Delete!!", "Deleted successfully!!");
    };

    const [SelectedIndex, setSelectedIndex] = React.useState();
    //const [ListSnippets, setListSnippets] = useState(props.snippets);
    // console.log("entro a code");

    const newSnippet = (event) => {
        //console.log('entro a nuevo');
        setInfoSnippet({ filename: "", title: "", avatar: "", date_at: "", author: "", description: "", id: 0, visibility: "" });
        setCode("");
        setView('new');
    };

    const editSnippet = (id, code) => {
        // console.log(id);
        setInfoSnippet(id);
        setSnippet_id(id.id);
        setCode(code);
        setView('new');
    };

    // const Items = props.snippets.map((snippet) => 
    //     <ListItemSnippet key={snippet.id} SelectIndex={SelectedIndex === snippet.id} OnClick={(event) => handleListItemClick(event, snippet.id, snippet.file_name, snippet.title, snippet.author.avatar_url, moment(snippet.created_at).format("DD/MM/YYYY, h:mm a"), snippet.author.name, snippet.description, snippet.visibility)} OnClickDelete={(event) => handleListItemClickDelete(event, snippet.id)} name={snippet.title} date={moment(snippet.created_at).format("DD/MM/YYYY, h:mm a")} author={snippet.author.username} image={snippet.author.avatar_url}></ListItemSnippet>
    // );
    let Items;

    //console.log(props);

    if (props.filtered !== undefined && props.filtered !== null) {
        Items = props.filtered.map((snippet) =>
            <ListItemSnippet key={snippet.id} SelectIndex={SelectedIndex === snippet.id} OnClick={(event) => handleListItemClick(event, snippet.id, snippet.file_name, snippet.title, snippet.author.avatar_url, moment(snippet.created_at).format("DD/MM/YYYY, h:mm a"), snippet.author.name, snippet.description, snippet.visibility)} OnClickDelete={(event) => handleListItemClickDelete(event, snippet.id)} name={snippet.title} date={moment(snippet.created_at).format("DD/MM/YYYY, h:mm a")} author={snippet.author.username} image={snippet.author.avatar_url}></ListItemSnippet>
        );
    }
    else {
        // console.log(props.snippets);
        if (props.snippets.length > 0) {
            Items = props.snippets.map((snippet) =>
                <ListItemSnippet key={snippet.id} SelectIndex={SelectedIndex === snippet.id} OnClick={(event) => handleListItemClick(event, snippet.id, snippet.file_name, snippet.title, snippet.author.avatar_url, moment(snippet.created_at).format("DD/MM/YYYY, h:mm a"), snippet.author.name, snippet.description, snippet.visibility)} OnClickDelete={(event) => handleListItemClickDelete(event, snippet.id)} name={snippet.title} date={moment(snippet.created_at).format("DD/MM/YYYY, h:mm a")} author={snippet.author.username} image={snippet.author.avatar_url}></ListItemSnippet>
            );
        }
        else {
            // console.log('sin snippets');
            // toastr.info("Empty!!", "Snippets empty!!");
        }

    }

    return (

        <Grid container>
            <Grid item xs={12} md={4} lg={3}>
                <>
                    <List className={classes.list} >
                        {Items}
                    </List>
                </>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
                {/* <Fade in={checkedView}>
                    <div>
                        <DivCode code={code} infoSnippet={InfoSnippet} languaje={languaje} ></DivCode>
                    </div>
                </Fade>
                <Fade in={checkedEdit}>
                    <div>
                        <FormEdit GuardarSnippet={GuardarSnippet}></FormEdit>
                    </div>
                </Fade> */}
                {view === "code" &&
                    <DivCode code={code} infoSnippet={InfoSnippet} languaje={languaje} editSnippet={editSnippet}></DivCode>
                }
                {view === "new" &&
                    <FormEdit GuardarSnippet={GuardarSnippet} infoSnippet={InfoSnippet} snippet={code} ></FormEdit>
                }

                {/* <EditorCode></EditorCode> */}
                <Fab className={clsx(classes.fab, classes.fabGreen)} aria-label="add" onClick={() => { newSnippet() }}>
                    <AddIcon />
                </Fab>
            </Grid>
        </Grid>

    );
}
