import React from 'react';
import Master1 from '../components/Master1';
import 'console-success';



export default function Home() {
    console.success("¡Welcome to the functionalities don't documented! ->  XD");

    return (
        <Master1>
        </Master1>
    )

}

