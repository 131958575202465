import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Grid } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import Fab from '@material-ui/core/Fab';
import { green } from '@material-ui/core/colors';
import clsx from 'clsx';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Typography from '@material-ui/core/Typography';

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialLight } from 'react-syntax-highlighter/dist/esm/styles/prism';

import { Field, useFormik } from 'formik';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      padding: theme.spacing(1),
      //width: '25ch',
    },
  },
  widthFull: {
    width: '100%',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(10),
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[600],
    },
  },
}));

export default function FolderList(props) {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: initialValues(props),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: (formData) => {
      props.GuardarSnippet(formData);
    },
  });

  const [titleFocused, setTitleFocused] = useState(false);

  const handleTitleFocus = () => {
    setTitleFocused(true);
  };

  const handleTitleBlur = () => {
    setTitleFocused(false);
    if (formik.values.file_name === '') {
      formik.setFieldValue('file_name', formik.values.title);
    }
  };

  return (
    <div>
      <form className={classes.root} noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
        <Typography variant="h4" gutterBottom>
          Snippet details
        </Typography>

        <Grid item xs={12} sm={12}>
          <TextField
            className={classes.widthFull}
            name="title"
            label="Title"
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={handleTitleBlur}
            onFocus={handleTitleFocus}
            error={formik.touched.title && formik.errors.title}
            value={formik.values.title}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            className={classes.widthFull}
            name="description"
            label="Description"
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.description && formik.errors.description}
            value={formik.values.description}
          />
        </Grid>
        <Grid item xs={12} sm={10}>
          <TextField
            className={classes.widthFull}
            name="file_name"
            label="File name"
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.file_name && formik.errors.file_name}
            value={formik.values.file_name}
          />
          <Typography variant="caption" gutterBottom>
            &nbsp;&nbsp;&nbsp;Give your file a name to add snippet highlightung, e.g. example.rb for Ruby, .js for
            javascript
          </Typography>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Checkbox
            name="chk_visibility"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            checked={formik.values.chk_visibility}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          Public
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            className={classes.widthFull}
            name="snippet"
            label="Snippet"
            multiline
            rows={50}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.snippet && formik.errors.snippet}
            value={formik.values.snippet}
          />
          {/* <SyntaxHighlighter
              language="jsx" // Reemplaza "jsx" con el lenguaje correspondiente a tu snippet
              style={materialLight} // Estilo de resaltado, puedes elegir otro estilo si lo prefieres
              className={classes.widthFull}
              name="snippet"
              label="Snippet"
              showLineNumbers // Opcional: Mostrar números de línea
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.snippet && formik.errors.snippet}
              value={formik.values.snippet}
              /> */}
        </Grid>
        <Fab className={clsx(classes.fab, classes.fabGreen)} name="btnSave" aria-label="add" type="submit" value={props.infoSnippet.id}>
          <SaveIcon />
        </Fab>
      </form>
    </div>
  );
}

function initialValues(props) {
  return {
    title: props.infoSnippet.title,
    description: props.infoSnippet.description,
    file_name: props.infoSnippet.filename,
    chk_visibility: props.infoSnippet.visibility === 'public' ? true : false,
    snippet: props.snippet,
  };
}

function validationSchema() {
  return {
    title: Yup.string().required(true),
    description: Yup.string(),
    file_name: Yup.string().required(true),
    // , chk_visibility: Yup.required(true)
    snippet: Yup.string(),
  };
}
