import React, { useContext, useEffect, Fragment, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SidebarSnippets from './SidebarSnippets';
import NavbarSnippets from './NavbarSnippets';
import InputBase from '@material-ui/core/InputBase';
import Login from './Login';
import SnippetsContext from './context/SnippetsContext';
import ListFiles from './ListFiles';
import { get_token } from './AuthCustom';
import { on } from "local-storage";
import { toastr } from './../components/notificaciones';
import { get_urlLoginGitlab } from './AuxSistema';

export default function Master1(props) {
    const [logueado, setLogueado] = useState(get_token() === undefined ? false : true);
    const respSnippets = true;
    const useStyles = makeStyles((theme) => ({
        grow: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        inputRoot: {
            color: 'inherit',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: '20ch',
            },
        },
    }));
    const classes = useStyles();

    const handleSearching = (textSearch) => {

        //console.log('Texto a buscar: ' + textSearch.target.value);
        filterSnippets(textSearch.target.value);

    };


    const snipContext = useContext(SnippetsContext);

    //console.log(snipContext);
    const { snippets, getSnippets, filtered, filterSnippets } = snipContext;



    useEffect(() => {
        console.log('en el useeffect');
        
        if (get_token() !== undefined) {

            getSnippets(setLogueado);
            console.log(`🚀 ~ file: Master1.js ~ line 60 ~ useEffect ~ respSnippets`, respSnippets)
            // if (!respSnippets) {
            //     //reLogginAut();
            //     setLogueado(false);
            // }
            // else
            //     setLogueado(true);
            // setLogueado(false);
        }
        //eslint-disable-next-line
    }, []);

    on('userOAuth', cambiarEstatus);
    function cambiarEstatus() {
        console.log('function cambioestatus');
        //setLogueado(true);
        //if (logeado) //NO ES NECESARIO PERO PARA QUE NO LO MARQUE AHI LO DEJO HAY QUE ELIMINARLO

        getSnippets(setLogueado);
        // if (!respSnippets) {
        //     //reLogginAut();
        //     setLogueado(false);
        // }
        // else
        //     setLogueado(true);
        toastr.success("Great!!", "Login successfully");
    };
    function logout() {
        setLogueado(false);
    };
    // console.log(snippets);

    const externalWindow = useRef(null);
    function reLogginAut() {
        externalWindow.current = window.open(
            get_urlLoginGitlab(),
            "Authenticate",
            "width=900,height=700,left=500,top=500"
        );

    }

    function newSnippetBarLeft() {

    }

    return (
        <Fragment>
            {!logueado ?
                <Login></Login>
                :
                <SidebarSnippets content={
                    <ListFiles snippets={snippets} filtered={filtered} setLogueado={setLogueado} ></ListFiles>
                } newSnippetBarLeft={newSnippetBarLeft}>
                    <NavbarSnippets logout={logout}>
                        <InputBase
                            placeholder="Search…"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            onKeyUp={handleSearching}
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </NavbarSnippets>
                </SidebarSnippets>
            }
        </Fragment>
    );
}