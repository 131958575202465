import React, { useReducer, useState } from 'react';
import axios from 'axios';

import SnippetsContext from './SnippetsContext';
import SnippetsReducer from './SnippetsReducer';

import { GET_SNIPPETS
, GET_SNIPPET
, DELETE_SNIPPET
, FILTER_SNIPPETS
, CLEAR_FILTER, 
ADD_SNIPPET} from './types';
import { get_token, refreshToken } from '../AuthCustom';
import { get_urlSnippets } from '../AuxSistema';


const SnippetsState = (props) => {
    const initialState = {
        snippets: [
                // {
                //     "id": 40,
                //     "title": "Init",
                //     "file_name": "mclaughlin.rb",
                //     "description": null,
                //     "visibility": "internal",
                //     "author": {
                //         "id": 22,
                //         "name": "USER",
                //         "username": "USER",
                //         "state": "active",
                //         "avatar_url": "https://www.gravatar.com/avatar/52e4ce24a915fb7e51e1ad3b57f4b00a?s=80&d=identicon",
                //         "web_url": "http://example.com/user0"
                //     },
                //     "updated_at": "2018-09-18T01:12:26.383Z",
                //     "created_at": "2018-09-18T01:12:26.383Z",
                //     "project_id": null,
                //     "web_url": "http://example.com/snippets/42",
                //     "raw_url": "http://example.com/snippets/42/raw"
                // }
            ]
    };


    const [state, dispatch] = useReducer(SnippetsReducer, initialState);
    const getSnippets = async (respSnippets) => {
        
        try {
            // axios.get(get_urlSnippets() + "/api/v4/snippets?per_page=2000&page=1" ,{
            //     headers: {
            //         'Authorization': 'Bearer ' + get_token(),
            //         //'Content-Type': 'application/json'
            //     }
            // }).then(
            //     (res) => 
            //     {
            //         console.log(`🚀 ~ file: SnippetsState.js:56 ~ getSnippets ~ res:`, res);
            //         if(res.error === "invalid_token")
            //         {
            //             //ACTUALIZAR EL TOKEN CON EL REFRESH TOKEN
            //             refreshToken().then((result) => {
            //                 getSnippets(respSnippets);

            //             }).catch((err) => {
            //                 // respSnippets = false;
            //             });
            //         }
            //         return res;
            //     }
            // ).then(
            //     (res) => {
            //         console.log(`🚀 ~ file: SnippetsState.js:71 ~ getSnippets ~ res:`, res);
            //         dispatch({ type: GET_SNIPPETS, payload: res });
            //     }
            // )
            // .catch((err) => {
            //     console.log(`🚀 ~ file: SnippetsState.js:78 ~ getSnippets ~ err:`, err);
            //     if(JSON.stringify(err).includes("401"))
            //         {
            //             console.log('entro a refrescar el token en el error');
            //             //ACTUALIZAR EL TOKEN CON EL REFRESH TOKEN
            //             refreshToken().then((result) => {
            //                 getSnippets(respSnippets);
            //             }).catch((err) => {
            //                 console.log(`🚀 ~ file: SnippetsState.js:87 ~ refreshToken ~ err:`, err);
            //                 // respSnippets = false;
            //             });
            //         }
            // });

            fetch(get_urlSnippets() + "/api/v4/snippets?per_page=2000&page=1", {
                    method: 'get',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + get_token(),
                    })
                })
                .then(res => {
                    console.log(res.status);
                    // res.json().then((result) => {
                    //     console.log(result);
                    // }).catch((err) => {
                    //     console.log(`🚀 ~ file: SnippetsState.js:61 ~ res.json ~ err:`, err);
                        
                    // });
                    
                    if(res.status === 401)
                        {
                            console.log('entro al refresh linea 67');
                            //ACTUALIZAR EL TOKEN CON EL REFRESH TOKEN
                            refreshToken().then((result) => {
                                console.log(`🚀 ~ file: SnippetsState.js:110 ~ refreshToken ~ result:`, result);
                                getSnippets(respSnippets);
                                return res.json();
                            }).catch((err) => {
                                respSnippets = false;
                            });;
                        }
                    
                    return res.json();
                })
                .then(
                    (result) => {
                        console.log(`🚀 ~ file: SnippetsState.js:69 ~ getSnippets ~ result:`, result);
                        if(result.error === "invalid_token")
                        {
                            //ACTUALIZAR EL TOKEN CON EL REFRESH TOKEN
                            refreshToken().then((result) => {
                                getSnippets();
                            }).catch((err) => {
                                respSnippets = false;
                            });;
                        }
                        // if (result.error !== undefined && result.error === "invalid_token")
                        //     // console.log(`🚀 ~ file: SnippetsState.js ~ line 63 ~ getSnippets ~ result.error`, result.error)
                        //     respSnippets = false;
                        else 
                            dispatch({ type: GET_SNIPPETS, payload: result });
                            
                    }
                )
                .catch(function (e){
                    console.log(`🚀 ~ file: SnippetsState.js ~ line 76 ~ getSnippets ~ e`, e);
                })
            

        } catch (error){
            console.log(`🚀 ~ file: SnippetsState.js:83 ~ getSnippets ~ error:`, error);
            //dispatch({ type: EMPLOYEE_ERROR, payload: error.response.msg });
        }
    }

    const getSnippet = async (id) => {
        try {
            axios.get("https://gitlab.com/api/v4/snippets/" + id + "/raw" ,{
                // method: 'get',
                // url: "https://gitlab.com/api/v4/snippets/" + id + "/raw",
                headers: {
                    'Authorization': 'Bearer ' + get_token(),
                    //'Content-Type': 'application/json'
                }
            }).then(
                (res) => 
                {
                    if(res.error === "invalid_token")
                    {
                        //ACTUALIZAR EL TOKEN CON EL REFRESH TOKEN
                        refreshToken().then((result) => {
                            getSnippet(id);
                        }).catch((err) => {
                            // respSnippets = false;
                        });;
                    }
                    //console.log(res.data);
                    dispatch({ type: GET_SNIPPET, payload: res.data });
                }
            );
        } catch (error){
            console.log(`🚀 ~ file: SnippetsState.js:114 ~ getSnippet ~ error:`, error);
            //dispatch({ type: EMPLOYEE_ERROR, payload: error.response.msg });
        }
    }


    const deleteSnippet = async (id) => {
        try {
            axios.delete("https://gitlab.com/api/v4/snippets/" + id ,{
                // method: 'get',
                // url: "https://gitlab.com/api/v4/snippets/" + id + "/raw",
                headers: {
                    'Authorization': 'Bearer ' + get_token(),
                    //'Content-Type': 'application/json'
                }
            }).then(
                (res) => 
                {
                    if(res.error === "invalid_token")
                    {
                        //ACTUALIZAR EL TOKEN CON EL REFRESH TOKEN
                        refreshToken().then((result) => {
                            deleteSnippet(id);
                        }).catch((err) => {
                            // respSnippets = false;
                        });;
                    }
                    // console.log(res.data)
                    dispatch({ type: DELETE_SNIPPET, payload: id });
                }
            )

        } catch (error){
            //dispatch({ type: EMPLOYEE_ERROR, payload: error.response.msg });
        }
    }
    const addSnippet = (snippet) => {
        dispatch({ type: ADD_SNIPPET, payload: snippet });
    }


    // Filtrar
    const filterSnippets = (text) => {
        dispatch({ type: FILTER_SNIPPETS, payload: text });
    };

    // Limpiar filtro
    const clearFilter = () => {
        dispatch({ type: CLEAR_FILTER });
    };

    return (
        <SnippetsContext.Provider 
        value={{
            snippets: state.snippets,
            filtered: state.filtered, 
            getSnippets
            , getSnippet
            , addSnippet
            , filterSnippets
            , clearFilter
            , deleteSnippet
        }}>
            {props.children}
        </SnippetsContext.Provider>
    );
}



export default SnippetsState;