import React from 'react';
import 'console-success';
// import { useHistory } from "react-router";
import jwt from "jwt-simple";
import { get_secretJWT, get_clientSecret, get_clientID, get_redirectURL } from "../components/AuthCustom";
import { set } from "local-storage";
import axios from 'axios';

export default function Oauth() {

    // let history = useHistory();

    const myURL = new URL(window.location.href);
    var code = myURL.searchParams.get('code');
    
    if (code !== undefined && code !== "" && code !== null) {
        // console.log(`🚀 ~ file: Oauth.js:17 ~ Oauth ~ code:`, code);
        //EL CODE REGRESA SOLO UN CODIGO COMO EL SIGUIENTE
        // ecc2ee135351c5613c35ef2ab5746bd1ecdef495d8921249a5dc0f1446264f9a

        var config = {
            method: 'post',
            url: `https://gitlab.com/oauth/token?client_id=${get_clientID()}&client_secret=${get_clientSecret()}&code=${code}&grant_type=authorization_code&redirect_uri=${get_redirectURL()}`,
            // headers: { 
            //     'Cookie': '__cfduid=d9eb480f37f943804c93fbe8560f4afd51612886801'
            // }
        };

        axios(config)
            .then(function (response) {
                
                if (response.data !== undefined && response.data !== null) {
                    //AQUI REGRESA EL response.data REGRESA EL SIGUIENTE OBJETO
                    // {
                    //     "access_token": "0c46087d39f6db4849a6d2e045c8863676a352d85cb940e96b1be3535ddd8dc3",
                    //     "token_type": "Bearer",
                    //     "expires_in": 7200,
                    //     "refresh_token": "f8a62dee7f67718e51a85350c67e61cda08172d39827954b2fe4b6785ce16b5b",
                    //     "scope": "api read_user",
                    //     "created_at": 1688760125
                    // }



                    console.log(`🚀 ~ file: Oauth.js:39 ~ response.data:`, response.data);
                    var token = jwt.encode(JSON.stringify(response.data), get_secretJWT());
                    set('userOAuth', token);
                    // history.push("/");
                    window.close();
                }
            })
            .catch(function (error) {
                console.log(error);
            });



    }
    else {
        //AÑADIR CODIGO PARA QUE VUELVA A SOLICITAR EL PERMISO
        console.log("El token no fue encontrado");
        
    }




    return (
        <div></div>
    );

}

