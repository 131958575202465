import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import { get_urlLoginGitlab } from './AuxSistema';

const useStyles = makeStyles((theme) =>({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  containerlogin:{
    display: 'flex',
    'justify-content': 'center',
    'align-items': 'center',
    height:'100vh' 
    
  },
  centrar:{
    'text-align': 'center',
  },
  buttonlogin:{
    display: 'grid'
  },
  large: {
    marginTop: 30,
    marginBottom: 30,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: theme.spacing(17),
    height: theme.spacing(17),
  },
}));

export default function SimpleCard(props) {
  const classes = useStyles();

  const externalWindow = useRef(null);
  function abrirModal(){
    externalWindow.current = window.open(
      get_urlLoginGitlab(),
      "Authenticate",
      "width=900,height=700,left=500,top=500"
    );
  }

  return (
    <Container maxWidth="sm" className={classes.containerlogin}>
    <Card className={classes.root}>
      <CardContent className={classes.centrar}>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Welcome to the snippets!!
        </Typography>
        <Avatar alt="Snippets" src="../template/images/SeekSnippetsLogo.svg" className={classes.large} />
        <Typography variant="body2" component="p">
          Login with GitLab please
        </Typography>
      </CardContent>
      <CardActions className={classes.buttonlogin}>
      <Button variant="contained" color="primary" onClick={abrirModal}>
        Login
      </Button>
      </CardActions>
    </Card>
    {/* <LoginExterna></LoginExterna> */}
    </Container>
  );
}


