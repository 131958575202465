import { get, set } from "local-storage";
import jwt from "jwt-simple";
import axios from "axios";

export function get_token() {
  var token;
  if (get("userOAuth") !== null && get("userOAuth") !== "") {
    var userOAuth = jwt.decode(get("userOAuth"), get_secretJWT());
    token = JSON.parse(userOAuth).access_token;
  }

  return token;
}

export function session_active(){
  return new Promise((resolve, reject) => {
    if (get("userOAuth") !== null && get("userOAuth") !== "") {
      resolve(true);
    }
    else{
      reject(false);
    }
  });
}

export function get_refreshToken() {
  return new Promise((resolve, reject) => {
    var token;
    if (get("userOAuth") !== null && get("userOAuth") !== "") {
      var userOAuth = jwt.decode(get("userOAuth"), get_secretJWT());
      token = JSON.parse(userOAuth).refresh_token;
      resolve(token);
    }
    else{
//       console.log('Ya no existe la sesion');
      reject('Ya no existe la sesion');
    }
    
//     return token;
  });  
}

export function get_userOAuth() {
  var userOAuth = jwt.decode(get("userOAuth"), get_secretJWT());
  return JSON.parse(userOAuth);
}

export function delete_userOAuth() {
  set("userOAuth", null);
}

export function get_clientID() {
  var appID = "37f0e0fdd9d198525e74bda56b909583a989f4188ebb505f06422af20c9afbaf"; //PRODUCCION
  // var appID = "5d68ce50c64f3268ac930269dd504768d08750df591e5d09d0613e4c735f2dae"; //LOCALHOST

  return appID;
}

export function get_clientSecret() {
  var clientSecret = "cec37acf0f4f9fcacf3570c1aee141c2db6c982fabbb0e9290964cb152ca6d6b"; //PRODUCCION
  // var clientSecret = "786556c542f3acde6afe6b93a2189781cc7b37101931774111e487f43e530fb3"; //LOCALHOST

  return clientSecret;
}

export function get_redirectURL() {
  var redirectURL = "https://snippets.engranity.com/oauth"; //PRODUCCION
  // var redirectURL = "http://127.0.0.1:3000/oauth"; //LOCALHOST

  return redirectURL;
}

export function get_secretJWT() {
  var secretJWT = "e74bda56b909583a989f4188ebb";
  
  return secretJWT;
}

export function get_UrlLogin() {
  var urlLogin = `https://gitlab.com/oauth/authorize?client_id=${get_clientID()}&redirect_uri=${get_redirectURL()}&response_type=code&state=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJjb2RlT0F1dGgiOiIxMjM0NTY3ODkwIn0.mzj7Qq2XiNHs8dP-qw_3CpQtv5iOTmygess3v2b_UdQ&scope=api read_user`;
  
  return urlLogin;
}

export function get_UrlRefreshToken() {
  return new Promise((resolve, reject) => {
    get_refreshToken().then((result) => {
      var urlRefreshToken = `https://gitlab.com/oauth/token?client_id=${get_clientID()}&client_secret=${get_clientSecret()}&refresh_token=${result}&grant_type=refresh_token&redirect_uri=${get_redirectURL()}`;
      resolve(urlRefreshToken);
    }).catch((err) => {
      reject('Session expired');
    });
  });
}

export function refreshToken() {
  return new Promise((resolve, reject) => {
    get_UrlRefreshToken().then((result) => {
      var config = {
        method: "post",
        url: `${result}`,
      };
      
      console.log(`🚀 ~ file: AuthCustom.js:137 ~ constget_UrlRefreshToken=get_UrlRefreshToken ~ get_UrlRefreshToken:`,config);
      axios(config)
        .then(function (response) {
          console.log(`🚀 ~ f----se refresca el token:`, response);
  
          if (response.data !== undefined && response.data !== null) {
            console.log(`🚀 ~ file: AuthCustom.js:116 ~ response.data:`, response.data);
            
            // AQUI REGRESA EL response.data REGRESA EL SIGUIENTE OBJETO
            // {
            //     "access_token": "0c46087d39f6db4849a6d2e045c8863676a352d85cb940e96b1be3535ddd8dc3",
            //     "token_type": "Bearer",
            //     "expires_in": 7200,
            //     "refresh_token": "f8a62dee7f67718e51a85350c67e61cda08172d39827954b2fe4b6785ce16b5b",
            //     "scope": "api read_user",
            //     "created_at": 1688760125
            // }
            var token = jwt.encode(
              JSON.stringify(response.data),
              get_secretJWT()
            );
            set("userOAuth", token);
            resolve();
          }
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    }).catch((err) => {
      reject(err);
    });
    
  });
}
