import { get, set } from "local-storage";
import jwt from "jwt-simple";
import { get_clientID, get_redirectURL, get_secretJWT } from "./AuthCustom";

export function get_urlSnippets() {
        if(get('urlSnippets') === null)
        {
                set('urlSnippets', jwt.encode("https://gitlab.com", get_secretJWT()));
        }
        var urlSnippets = jwt.decode(get('urlSnippets'), get_secretJWT());
        if(urlSnippets === undefined || urlSnippets === null || urlSnippets === "")
        {
                urlSnippets = "https://gitlab.com";
        }
        return urlSnippets;
}

export function set_urlSnippets(url = "https://gitlab.com") {
        var urlSnippets = jwt.encode(url, get_secretJWT());
        set('urlSnippets', urlSnippets);
        return urlSnippets;
}


export function get_urlLoginGitlab() {
        var urlLoginGitlab = `https://gitlab.com/oauth/authorize?client_id=${get_clientID()}&redirect_uri=${get_redirectURL()}&response_type=code&state=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJjb2RlT0F1dGgiOiIxMjM0NTY3ODkwIn0.mzj7Qq2XiNHs8dP-qw_3CpQtv5iOTmygess3v2b_UdQ&scope=api read_user`;
        return urlLoginGitlab;
}